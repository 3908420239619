<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card outlined>
          <v-card-title class="card-title">
            <img alt="Logo" src="../assets/stru_logo.png" class="mr-4" />
            <div v-html="header" class="text-heading-6"></div>
            <v-spacer></v-spacer>
            <v-btn icon v-if="canEdit && state === 'view'" @click="edit"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-skeleton-loader v-if="state === 'loading'" type="list-item-avatar-three-line"></v-skeleton-loader>
            <div v-if="state === 'error'">
              <div v-if="statusCode === 404">
                <p>Регистрация с указанным идентификатором не найдена в нашей базе данных.</p>
                <p>Свяжитесь с нами по адресу <a href="mailto:trainings@software-testing.ru">trainings@software-testing.ru</a>, сообщите, на какой тренинг вы регистрировались и с каким адресом электронной почты, и мы постараемся найти потерянную регистрацию.</p>
              </div>
              <div v-else>
                <p>При загрузке информации возникла непредвиденная ошибка.</p>
                <p>Скорее всего мы уже получили оповещение об этом и работаем над устранением проблемы, но если у вас срочный вопрос, вы можете связаться с нами по адресу <a href="mailto:trainings@software-testing.ru">trainings@software-testing.ru</a></p>
              </div>
            </div>
            <div v-if="state === 'view'">
              <p><strong>{{ submission.organisation }}</strong>, заявка получена: {{ formatDate(submission.created) }}</p>
              <p>Контактное лицо: {{ submission.representative }} <a :href="`mailto:${submission.representativeEmail}`">{{ submission.representativeEmail }}</a></p>
              <div>
                <p class="text-h6">Данные для заключения договора</p>
                <p><strong>{{ submission.organisation }}</strong> в лице {{ submission.representedBy }} и <strong>{{ submission.contractor.title }}</strong></p>
                <p>заключают договор на участие в тренинге "{{ submission.courseRun.title }}", начало {{ formatDate(submission.courseRun.start) }}</p>
                <p class="text-subtitle-1 font-weight-bold">Участники:</p>
                <v-data-table :headers="headers" :items="submission.participations" class="elevation-1 mb-5" dense item-key="uuid" disable-pagination hide-default-footer></v-data-table>
                <p class="text-subtitle-1 font-weight-bold">Адрес организации:</p>
                <p class="text-pre-wrap">{{ submission.address }}</p>
                <p class="text-subtitle-1 font-weight-bold">Реквизиты организации:</p>
                <p>ИНН: {{ submission.inn }}, КПП: {{ submission.kpp }}</p>
                <p class="text-pre-wrap">{{ submission.requisites }}</p>
                <p><strong>Подписант:</strong> {{ submission.signerPosition }} {{ submission.signer }}</p>
              </div>
            </div>
            <v-card v-if="state === 'edit'" flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="pl-0">
                      <v-text-field v-model="submissionToEdit.student" label="ФИО" dense></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="submissionToEdit.studentEmail" label="E-mail" dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-0">
                      <v-select :items="paymentStatuses" v-model="submissionToEdit.paidStatus" label="Статус оплаты" dense></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="submissionToEdit.price" label="Сумма" dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-0">
                      <v-textarea v-model="submissionToEdit.comments" label="Комментарий" rows="3" auto-grow dense></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn outlined @click="save">Сохранить</v-btn>
                <v-btn outlined @click="cancel">Не сохранять</v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay v-if="state === 'progress'"></v-overlay>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

moment.locale('ru')

require('dotenv').config()

export default {
  name: 'OrgSubmission',
  components: {
  },
  props: {
    uuid: String
  },
  data: function () {
    return {
      state: 'initial',
      submission: undefined,
      submissionToEdit: undefined,
      canEdit: false,
      headers: [
        { text: 'ФИО', value: 'student', sortable: false },
        { text: 'E-mail', value: 'studentEmail', sortable: false }
      ]
    }
  },
  computed: {
    header() {
      switch (this.state) {
        case 'initial':
        case 'loading':
          return 'Заявка на участие в тренинге<br>...'
        case 'view':
        case 'edit':
          return `Заявка на участие в тренинге<br>${this.submission.courseRun.title}, ${this.submission.courseRun.number}`
        case 'error':
          return `Ошибка ${this.statusCode}<br>${this.errorMessage}`
        default:
          return 'WTF ??!!'
      }
    }
  },
  methods: {
    edit () {
      this.submissionToEdit = {
        uuid: this.submission.uuid,
        comments: this.submission.comments,
      }
      this.state = 'edit'
    },
    async save () {
      try {
        this.state = 'progress'
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/orgsubmission/${this.uuid}`, { authToken: localStorage.getItem("authtoken"), data: this.submissionToEdit })
        this.submission = { ...this.submission, ...this.submissionToEdit }
      } catch (error) {
        console.log(error)
      } finally {
        this.submissionToEdit = undefined
        this.state = 'view'
      }
    },
    cancel () {
      this.submissionToEdit = undefined
      this.state = 'view'
    },
    formatDate (date) {
      return moment(date).format('DD MMMM YYYY')
    }
  },
  async mounted() {
    this.state = 'loading'
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/orgsubmission/${this.uuid}`)
      this.submission = response.data
    } catch (error) {
      if (error.response) {
        console.log(error.response)
        this.state = 'error'
        this.statusCode = error.response.status
        this.errorMessage = JSON.stringify(error.response.data)
        document.title = '404 Not Found'
      }
    } finally {
      if (this.state !== 'error') {
        this.state = 'view'
        document.title = 'Регистрация на тренинг'
      }
    }
    const authToken = localStorage.getItem("authtoken")
    if (authToken) {
      try {
        await axios.get(`${process.env.VUE_APP_API_BASE_URL}/auth/${authToken}`)
        this.canEdit = true
      } catch (error) {
        this.canEdit = false
      }
    }
  }
}
</script>
